<template>
  <div class="flex-center position-ref full-height">
    <div class="error-page">
      <div class="error-content">
          <h2 class="headline text-yellow" style="font-size: 50px;"> 404</h2>
        <h3><i class="fa fa-warning text-yellow"></i> {{ $t('errorView.text1')}}</h3>
        <p>
          {{ $t('errorView.text2')}}
        </p>
      </div>
      <br>
      <img src="../../public/img/firma_precompro.png">
    </div>
    <br>
  </div>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
export default {
  metaInfo: {
    title: 'Página no encontrada'
  },
  mounted () {
    this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
  }
};
</script>
<style scoped>
  html, body {
    background-color: #fff;
    height: 100vh;
    margin: 0;
    color: #636b6f;
  }

  .full-height {
    height: 100vh;
  }

  .flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .position-ref {
    position: relative;
  }

  .top-right {
    position: absolute;
    right: 10px;
    top: 18px;
  }

  .content {
    text-align: center;
  }

  .error-page {
    width: 600px;
    margin-top: -10%;
  }

  .text-yellow {
    color: #dcd500 !important;
  }
</style>
